<template>
    <div id="main">
        <div id="banner">
            <img :src="require('@/assets/images/logoWhite.png')" alt="INCHARGE" />
            <v-toolbar dense floating style="z-index: 1; position: absolute; right: 5vw; top: 18vh">
                <v-btn icon @click="showQRCode = true">
                    <v-icon>qr_code_scanner</v-icon>
                </v-btn>
                <v-btn icon>
                    <v-icon @click="getLocation">my_location</v-icon>
                </v-btn>
                <v-btn icon>
                    <v-icon @click="zoomOut">zoom_out</v-icon>
                </v-btn>
                <v-btn icon>
                    <v-icon @click="zoomIn">zoom_in</v-icon>
                </v-btn>
                <v-btn icon @click="filter.show = true">
                    <v-icon>filter_list</v-icon>
                </v-btn>
            </v-toolbar>

            <div class="text-xs-center">
                <v-menu v-model="filter.show" :close-on-content-click="false" :nudge-width="200" offset-x>
                    <v-card>
                        <v-list>
                            <v-list-item link>
                                <v-list-item-content>
                                    <v-list-item-title class="title">Filtro</v-list-item-title>
                                    <v-list-item-subtitle>Marque para visualizar no mapa</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-icon>mdi-menu-down</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <v-divider />
                        <v-list>
                            <v-list-item>
                                <v-list-item-action>
                                    <v-switch v-model="filter.available" color="primary" />
                                </v-list-item-action>
                                <v-list-item-title>Disponíveis</v-list-item-title>
                                <v-list-item-avatar>
                                    <v-img :src="require('@/assets/images/iconGreen.png')" alt="Disponíveis" />
                                </v-list-item-avatar>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-action>
                                    <v-switch v-model="filter.busy" color="primary" />
                                </v-list-item-action>
                                <v-list-item-title>Ocupados</v-list-item-title>
                                <v-list-item-avatar>
                                    <v-img :src="require('@/assets/images/iconRed.png')" alt="Ocupados" />
                                </v-list-item-avatar>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-action>
                                    <v-switch v-model="filter.offline" color="primary" />
                                </v-list-item-action>
                                <v-list-item-title>Offline</v-list-item-title>
                                <v-list-item-avatar>
                                    <v-img :src="require('@/assets/images/iconGray.png')" alt="Offline" />
                                </v-list-item-avatar>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>
                                    <v-container><v-text-field v-model="filter.name" label="Filtrar por nome" outlined clearable /></v-container></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </div>
        </div>
        <div id="content">
            <v-progress-linear v-if="loading" :indeterminate="true" color="primary" height="12" />
            <l-map :zoom="map.zoom" :center="map.currentLocation" id="map" ref="myMap" :options="{ zoomControl: false }">
                <l-tile-layer :url="map.url"></l-tile-layer>
                <l-marker :lat-lng="map.currentLocation" :icon="currentLocationIcon"></l-marker>
                <l-marker v-for="point of pointsWithFilter" :lat-lng="point.cord" :icon="icon(point)" :key="point.key" @click="showDetails(point)"
                    :zIndexOffset="iconIndex(point)" />
            </l-map>
        </div>

        <QRCode :show="showQRCode" @close="showQRCode = false" @onRead="readedQRCode" />
        <PointDetails :point="modalData" :show="showModal" @close="closeDetails"></PointDetails>
    </div>
</template>

<script>
import QRCode from "@/components/core/QRCode";
import PointDetails from "./PointDetails";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

import { LMap, LTileLayer, LMarker } from "vue2-leaflet";

export default {
    name: "Home",

    mounted() {
        this.getFilter();

        try {
            if (localStorage.getItem("points")) {
                this.setPoints(JSON.parse(localStorage.getItem("points")));
                //this.onConnectionFailed();
            }
        } catch (error) {
            console.log(error);
        }
        try {
            this.mapRef = this.$refs.myMap.mapObject;
            this.getLocation(false);
        } catch (error) {
            console.log(error);
        }
        this.update();
        this.timer = setInterval(this.update, 10000);
    },

    components: {
        QRCode,
        PointDetails,
        LMap,
        LTileLayer,
        LMarker,
    },

    data() {
        return {
            loading: true,
            showQRCode: false,
            filter: {
                show: false,
                available: true,
                busy: true,
                offline: true,
                car: true,
                bike: true,
                name: "",
            },
            timer: null,
            mapRef: null,
            showModal: false,
            modalData: null,
            map: {
                zoom: 9,
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                currentLocation: [-23.148706, -46.551336],
            },
            points: [],
            available: 0,
            availableOthers: 0,
            connected: 0,
            connectedOthers: 0,
            charging: 0,
            chargingOthers: 0,
            offline: 0,
            offlineOthers: 0,
        };
    },

    methods: {
        getFilter() {
            try {
                if (!this.$store.getters.isLogged) {
                    this.filter.bike = true;
                    this.filter.car = true;
                } else {
                    this.filter.bike = this.$store.state.user.bike;
                    this.filter.car = this.$store.state.user.car;
                }
            } catch (error) {
                console.log(error);
            }
        },
        readedQRCode(QRCode) {
            if (QRCode.startsWith(window.location.origin)) {
                this.showQRCode = false;
                window.location.href = QRCode;
            } else {
                this.$eventHub.$emit("msgError", this.$t("Código inválido."));
            }
        },
        showDetails(point) {
            this.modalData = point;
            this.showModal = true;
        },
        closeDetails() {
            this.modalData = null;
            this.showModal = false;
            // this.mapRef.setZoom(9);
        },
        zoomIn() {
            this.mapRef.setZoom(this.mapRef.getZoom() + 1);
        },
        zoomOut() {
            this.mapRef.setZoom(this.mapRef.getZoom() - 1);
        },
        getLocation(forceZoom = true) {
            let mainContext = this;
            navigator.geolocation.getCurrentPosition(function (location) {
                mainContext.map.currentLocation = L.latLng(location.coords.latitude, location.coords.longitude);
                mainContext.mapRef.panTo(L.latLng(location.coords.latitude, location.coords.longitude));
                if (forceZoom) {
                    mainContext.mapRef.setZoom(17);
                }
            });
        },
        update() {
            this.getFilter();
            this.$http
                .get(this.filter.car == true && this.filter.bike == true ? "api/v2/points" : this.filter.bike == true ? "api/v2/bike/points" : "api/v2/car/points", {
                    continuous: true,
                })
                .then((result) => {
                    try {
                        localStorage.setItem("points", JSON.stringify(result));
                    } catch (error) {
                        console.log(error);
                    }
                    this.setPoints(result);
                    this.loading = false;
                })
                .catch(() => {
                    this.onConnectionFailed();
                });
        },
        onConnectionFailed() {
            if (this.points.length > 0) {
                this.points.forEach((point) => {
                    point.chargers.forEach((charger) => {
                        charger.status = "offline";
                    });
                });
                this.setPoints(this.points);
            }
        },
        getIconName(point) {
            if (point.available > 0) {
                return "Available";
            } else if (point.availableOthers > 0) {
                return "AvailableOthers";
            } else if (point.availableBike > 0) {
                return "AvailableBike";
            } else if (point.connected > 0 || point.charging > 0) {
                return "Busy";
            } else if (point.connectedOthers > 0 || point.chargingOthers > 0) {
                return "BusyOthers";
            } else if (point.connectedBike > 0 || point.chargingBike > 0) {
                return "BusyBike";
            } else if (point.offline > 0) {
                return "Offline";
            } else if (point.offlineBike > 0) {
                return "OfflineBike";
            } else {
                return "OfflineOthers";
            }
        },
        setPoints(newPoints) {
            this.points = newPoints;
            try {
                this.points.forEach((point) => {
                    if (point) {
                        point.hasBike = point.chargers.reduce((total, x) => (x.eBike === true ? total + 1 : total), 0);

                        point.available = point.chargers.reduce((total, x) => (x.status == "Available" && x.incharge === true ? total + 1 : total), 0);
                        point.availableOthers = point.chargers.reduce((total, x) => (x.status == "Available" && x.incharge === false ? total + 1 : total), 0);
                        point.availableBike = point.chargers.reduce((total, x) => (x.status == "Available" && x.eBike === true ? total + 1 : total), 0);

                        point.connected = point.chargers.reduce((total, x) => (x.status == "Unavailable" && x.incharge === true ? total + 1 : total), 0);
                        point.connectedOthers = point.chargers.reduce((total, x) => (x.status == "Unavailable" && x.incharge === false ? total + 1 : total), 0);
                        point.connectedBike = point.chargers.reduce((total, x) => (x.status == "Unavailable" && x.eBike === true ? total + 1 : total), 0);

                        point.charging = point.chargers.reduce((total, x) => (x.status == "Charging" && x.incharge === true ? total + 1 : total), 0);
                        point.chargingOthers = point.chargers.reduce((total, x) => (x.status == "Charging" && x.incharge === false ? total + 1 : total), 0);
                        point.chargingBike = point.chargers.reduce((total, x) => (x.status == "Charging" && x.eBike === true ? total + 1 : total), 0);

                        point.offline = point.chargers.reduce((total, x) => (x.status == "Faulted" && x.incharge === true ? total + 1 : total), 0);
                        point.offlineOthers = point.chargers.reduce((total, x) => (x.status == "Faulted" && x.incharge === false ? total + 1 : total), 0);
                        point.offlineBike = point.chargers.reduce((total, x) => (x.status == "Faulted" && x.eBike === true ? total + 1 : total), 0);
                    }
                });
            } catch (error) {
                console.log(error)
            }
        },
    },

    computed: {
        pointsWithFilter() {
            if (this.points.length > 0) {
                return this.points.filter((point) => {
                    let filterName = true;

                    if (this.filter.name) {
                        let exp = new RegExp(
                            this.filter.name.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                            "i");
                        const chargersString = point.chargers.map(c => c.name).join('');
                        filterName = exp.test(`${point.t}${point.city}${chargersString}`.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
                    }

                    return (
                        (filterName) &&
                        ((this.filter.available && (point.available > 0 || point.availableOthers > 0 || point.availableBike > 0)) ||
                            (this.filter.busy &&
                                (point.connected > 0 ||
                                    point.connectedOthers > 0 ||
                                    point.connectedBike > 0 ||
                                    point.charging > 0 ||
                                    point.chargingOthers > 0 ||
                                    point.chargingBike > 0)) ||
                            (this.filter.offline &&
                                (point.offline > 0 ||
                                    point.offlineOthers > 0 ||
                                    // ESSA CONDIÇÃO É POIS EXISTE PONTOS SEM CARREGADORES E OS MESMOS SÃO MOSTRADOS COMO OFFLINE
                                    (point.available == 0 &&
                                        point.availableOthers == 0 &&
                                        point.connected == 0 &&
                                        point.connectedOthers == 0 &&
                                        point.charging == 0 &&
                                        point.chargingOthers == 0))))
                    );
                });
            }
            return [];
        },
        currentLocationIcon() {
            return L.icon({
                iconUrl: require("@/assets/images/currentLocation.png"),
                iconSize: [28, 28],
                iconAnchor: [20, 20],
            });
        },
        icon() {
            return (point) =>
                L.icon({
                    iconUrl: require("@/assets/images/icon" + this.getIconName(point) + ".png"),
                    iconSize: point.hasBike ? [35, 26] : [28, 34], // Icone do ebike tem proporção diferente
                    iconAnchor: [20, 20],
                });
        },
        iconIndex() {
            return (point) => {
                if (point.available > 0) {
                    return 1000;
                } else if (point.connected > 0 || point.charging > 0) {
                    return 900;
                } else if (point.availableOthers > 0) {
                    return 850;
                } else if (point.connectedOthers > 0 || point.chargingOthers > 0) {
                    return 840;
                } else if (point.offline > 0) {
                    return 800;
                } else {
                    return 700;
                }
            };
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>

<style scoped>
#map {
    z-index: 0;
}

#content {
    width: 100vw;
    height: 84vh;
}

#banner {
    width: 100vw;
    height: 16vh;
    background-color: var(--v-primary-base);
}

#banner img {
    width: auto;
    height: 14vh;
    margin-top: 1vh;
}
</style>