import axios from 'axios'
import store from '@/helpers/store'
import router from '@/helpers/router'

function getDomain() {
    const hostname = window.location.hostname.replace('www.', '');
    let parts = hostname.split(".");
    if (parts.length <= 2) {
        return hostname;
    }
    parts = parts.slice(-3);
    if (['co', 'com'].indexOf(parts[1]) > -1) {
        return parts.join('.');
    }
    return parts.slice(-2).join('.');
}

const httpDefault = axios.create({
    baseURL: (process.env.NODE_ENV === 'production') ? `https://api.${getDomain()}:3000/` : `http://10.8.0.6:3000/`,
});

const httpNewBackend = axios.create({
    baseURL: (process.env.NODE_ENV === 'production') ? `https://backend.${getDomain()}:3001/` : `http://10.8.0.6:3001/`,
});

httpDefault.interceptors.request.use(req => {
    if (store.getters.isLogged) {
        req.headers.Authorization = `Bearer ${store.state.token}`;
    }
    return req;
}, error => {
    return Promise.reject(error);
});

httpNewBackend.interceptors.request.use(req => {
    if (store.getters.isLogged) {
        req.headers.Authorization = `Bearer ${store.state.token}`;
    }
    return req;
}, error => {
    return Promise.reject(error);
});

async function requestError(error, http) {
    const originalRequest = error.config;

    const status = error.response ? error.response.status : null;
    if (status === 401) {
        if (error.response.config.url != "api/v2/auth" && store.state.login.remember) {
            store.commit("AUTH_ERROR");
            console.log(store.getters.getCounter401)
            if (store.getters.getCounter401 > 10) {
                store.commit("CLEAR_LOGIN");
                if (router.currentRoute.path != "/") {
                    router.replace({ path: "/" });
                }
            } else {
                let req = { email: store.state.login.email, password: store.state.login.password, notification: store.state.notification };
                await httpDefault.post("api/v2/auth", req).then(response => {
                    store.commit("SET_LOGIN", { response, email: store.state.login.email, password: store.state.login.password, remember: true });
                }).catch(() => {
                    console.log("ERROOOOOOORRRRRRRRRRRRRR ====================")
                    store.commit("CLEAR_LOGIN");
                    if (router.currentRoute.path != "/") {
                        router.replace({ path: "/" });
                    }
                });
                return http.request(originalRequest);
            }
        } else {
            store.commit("CLEAR_LOGIN");
        }
    } else if (status === 403) {
        if (router.currentRoute.path != "/") {
            router.replace({ path: "/" });
        }
    }

    if (error.response) {
        store.commit("ONLINE");
    } else {
        store.commit("OFFLINE");
    }

    return error.response ? Promise.reject(error.response.data) : Promise.reject({ "message": "Sistema desconectado." });
}

httpDefault.interceptors.response.use(response => {
    store.commit("ONLINE");
    return response.data;
}, error => requestError(error, httpDefault));

// httpNewBackend.interceptors.response.use(response => {
//     store.commit("ONLINE");
//     return response.data;
// }, async function (error) {
//     console.log(error)
//     return error.response ? Promise.reject(error.response.data) : Promise.reject({ "message": "Sistema desconectado." });
// });

httpNewBackend.interceptors.response.use(response => {
    store.commit("ONLINE");
    return response.data;
}, error => requestError(error, httpNewBackend));

export const httpAlternative = httpNewBackend
export const http = httpDefault

export default httpDefault