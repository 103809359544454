import { http, httpAlternative } from '@/helpers/http'
import store from '@/helpers/store'
import eventHub from '@/eventHub'

async function postInternal(httpService, url, sendData, silentRequest = false) {
    eventHub.$emit("msgClose");
    store.commit("SENDING_REQUEST", true);
    if (silentRequest) store.commit("LOADING", true);
    return httpService.post(url, sendData).finally(() => {
        store.commit("SENDING_REQUEST", false);
        if (silentRequest) store.commit("LOADING", false);
    });
}

async function getInternal(httpService, url, continuous, showLoading) {
    if (!continuous || !store.getters.isSendingRequest) {
        store.commit("SENDING_REQUEST", true);

        // This logic is to not close LOADING message before load first request when user transation on pooling screen to screen with only one request
        let firstRequest = store.getters.isFirstRequest;
        if (firstRequest) {
            store.commit("FIRST_REQUEST", false);
        }
        if (firstRequest || (continuous == false && showLoading == true)) {
            store.commit("LOADING", true);
        }

        return httpService.get(url).finally(() => {
            store.commit("SENDING_REQUEST", false);
            if (firstRequest || (continuous == false && showLoading == true)) {
                store.commit("LOADING", false);
            }
        });
    } else {
        return new Promise((resolve, reject) => {
            window.console.error(`Ignoring request ${url}. Cause: continuos enable and detect pending request : ${continuous} : ${store.getters.isSendingRequest}`);
            reject();
        });
    }
}

export default {

    getMessage(res) {
        if (Array.isArray(res)) {
            let messages = res.map((i) => {
                return i.message;
            });
            return messages;
        } else if (res.message) {
            return res.message;
        }
        return null;
    },

    async delete(url, { id = null } = {}) {
        eventHub.$emit("msgClose");
        store.commit("SENDING_REQUEST", true);
        store.commit("LOADING", true);
        return http.delete(id == null ? `${url}` : `${url}/${id}`)
            .finally(() => {
                store.commit("SENDING_REQUEST", false);
                store.commit("LOADING", false);
            });
    },
    async put(url, id, updateData) {
        eventHub.$emit("msgClose");
        store.commit("SENDING_REQUEST", true);
        store.commit("LOADING", true);
        return http.put(`${url}/${id}`, updateData)
            .finally(() => {
                store.commit("SENDING_REQUEST", false);
                store.commit("LOADING", false);
            });
    },
    async post(url, sendData) {
        return postInternal(http, url, sendData);
    },
    async patch(url, { id = null, data = {} } = {}) {
        eventHub.$emit("msgClose");
        store.commit("SENDING_REQUEST", true);
        store.commit("LOADING", true);
        return http.patch(id == null ? `${url}` : `${url}/${id}`, data)
            .finally(() => {
                store.commit("SENDING_REQUEST", false);
                store.commit("LOADING", false);
            });
    },
    async postImage(url, sendData, receptor) {
        eventHub.$emit("msgClose");
        store.commit("SENDING_REQUEST", true);
        store.commit("LOADING", true);
        return http.post(url, sendData, receptor)
            .finally(() => {
                store.commit("SENDING_REQUEST", false);
                store.commit("LOADING", false);
            });
    },
    async get(url, { continuous = false, showLoading = true } = {}) {
        return getInternal(http, url, continuous, showLoading);
    },
    async getFile(url, fileName) {
        store.commit("SENDING_REQUEST", true);
        store.commit("LOADING", true);

        return http.request({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then(blob => {
            const data = URL.createObjectURL(blob)
            var link = document.createElement('a')
            link.href = data
            link.target = '_blank'
            if (fileName) {
                //     link.download = fileName;
            }
            link.click()
            window.URL.revokeObjectURL(blob)
        }).finally(() => {
            store.commit("SENDING_REQUEST", false);
            store.commit("LOADING", false);
        });
    },
    async postAlternativeAPI(url, sendData, silentRequest = false) {
        return postInternal(httpAlternative, url, sendData, silentRequest);
    },
    async getAlternativeAPI(url, { continuous = false, showLoading = true } = {}) {
        return getInternal(httpAlternative, url, continuous, showLoading);
    },
    async postDynamic(baseURL, url, sendData, silentRequest = false) {
        if (baseURL) {
            return this.postAlternativeAPI((process.env.NODE_ENV === 'production') ? `${baseURL}${url}` : url, sendData, silentRequest);
        } else {
            return this.postAlternativeAPI(url, sendData, silentRequest);
        }
    },
    async getDynamic(baseURL, url, { continuous = false, showLoading = true } = {}) {
        if (baseURL) {
            return this.getAlternativeAPI((process.env.NODE_ENV === 'production') ? `${baseURL}${url}` : url, { continuous, showLoading });
        } else {
            return this.getAlternativeAPI(url, { continuous, showLoading });
        }
    },
};